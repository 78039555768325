<!-- @format -->

<template>
  <div>
    <table class="w-full">
      <thead class="bg-teal text-left">
        <th
          v-for="(heading, id) in tableHeaders"
          :key="id"
          class="px-6 py-4 text-white font-thin text-sm"
        >
          {{ heading }}
        </th>
      </thead>
      <tbody v-if="props.isDash">
        <tr v-for="(data, id) in tableBody" :key="id" class="border bottom-1">
          <td class="px-5 py-5 font-thin text-sm max-clamp truncate">
            {{ data?.reference_number }}
          </td>
          <td class="px-5 py-5 font-thin text-sm">
            {{ data?.complaint_staff_group?.name }}
          </td>
          <td class="px-5 py-5 font-thin text-sm">
            {{ data?.complaint_subject?.name }}
          </td>
          <td class="px-5 py-5 font-thin text-sm">
            <span :class="getStatus(data)?.style" class="px-2 py-1 rounded">
              {{ getStatus(data)?.title }}</span
            >
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(data, id) in tableBody" :key="id" class="border bottom-1">
          <td class="px-6 py-5 font-thin text-sm max-clamp truncate">
            {{ data?.reference_number }}
          </td>
          <td class="px-6 py-5 font-thin text-sm">
            {{ data?.complaint_subject?.name }}
          </td>

          <td class="px-6 py-5 font-thin text-sm max-clamp truncate">
            {{ data?.complaint_staff_group?.name }}
          </td>

          <td
            v-if="!isManager"
            class="px-6 py-5 font-thin text-sm max-clamp truncate"
          >
            {{ data?.complaint_practice?.practice_name }}
          </td>

          <td class="px-6 py-5 font-thin text-sm max-clamp truncate">
            {{
              moment(
                tabIndex === 0
                  ? data?.receiving_date
                  : data?.status > 1
                  ? data?.complaint_outcome?.conclusion_date
                  : data?.complaint_acknowledgement?.acknowledgement_date
              ).format("MMM DD, YYYY")
            }}
          </td>
          <td
            v-if="tabIndex <= 1"
            class="px-6 py-5 font-thin text-sm max-clamp truncate"
          >
            {{
              tabIndex === 0
                ? data?.age_group?.slice(3)
                : moment(
                    data?.complaint_acknowledgement?.extension_date
                      ? data?.complaint_acknowledgement?.extension_date
                      : data?.complaint_acknowledgement?.due_date
                  ).format("MMM DD, YYYY")
            }}
          </td>
          <td
            v-if="tabIndex === 1"
            class="px-6 py-5 font-thin text-sm max-clamp truncate"
          >
            <span
              class="p-1 rounded"
              :class="
                data?.complaint_acknowledgement?.remaining_days > 40
                  ? 'bg-red-100 text-red'
                  : ' bg-orange bg-opacity-20 text-orange'
              "
              >{{ data?.complaint_acknowledgement?.remaining_days }}</span
            >
          </td>
          <td class="w-28">
            <span
              class="border-teal border px-2 py-1 font-thin text-sm rounded-lg text-teal cursor-pointer hover:bg-teal hover:text-white"
              @click="navigate(parseInt(data?.id))"
            >
              View Here
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="bg-white flex justify-end text-teal px-3 py-2 text-sm items-center gap-1 cursor-pointer"
      @click="navigateToDash()"
      v-if="props.isDash && isManager"
    >
      View Here
      <span class="text-teal material-icons text-lg"> launch</span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

const props = defineProps({
  tableHeading: {
    type: Array,
    required: true,
  },
  tableBody: {
    type: Array,
    required: true,
  },
  isManager: {
    type: Boolean,
    required: true,
  },
  tabIndex: {
    type: Number,
    required: true,
  },
  isDash: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const tableHeaders = computed(() => {
  if (isManager.value && !props.isDash) {
    const headers = [...tableHeading.value];
    headers.splice(3, 1);
    return headers;
  } else {
    return tableHeading.value;
  }
});

const getStatus = (data) => {
  switch (data?.status) {
    case 0:
    case 1:
      return {
        title: "Pending",
        style: "text-orange  bg-orange bg-opacity-10",
      };
    case 2:
    case 3:
    case 4:
      return {
        title: "Resolved",
        style: "text-green bg-green bg-opacity-10",
      };
    case 5:
      return {
        title: "Un Resolved",
        style: "text-red bg-red-800 bg-opacity-10",
      };
    default:
      return { title: "Unknown", style: "text-gray-500" };
  }
};

const navigate = (complaintId) => {
  if (isManager.value) {
    router.push({
      name: "ma-monitoringOfPracticeFunctionComplaint-view",
      params: { isEnabled: 0, complaintId: complaintId, isManager: 1 },
    });
  } else {
    router.push({
      name: "hq-monitoringOfPracticeFunctionsCreateComplaints",
      params: { isEnabled: 0, complaintId: complaintId, isManager: 0 },
    });
  }
};

const navigateToDash = () => {
  router.push({
    name: "ma-monitoringOfPracticeFunctionComplaintsDashboard",
    params: { isManager: 1 },
  });
};

const { tableHeading, tableBody, isManager, tabIndex } = toRefs(props);
</script>

<style scoped>
.max-clamp {
  max-width: 150px;
}
</style>
